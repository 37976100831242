<template>
  <v-card flat>
    <v-toolbar flat v-if="max_h"
      ><v-btn @click="zoom_in"> <v-icon>mdi-plus</v-icon> </v-btn
      ><v-btn @click="zoom_out">
        <v-icon>mdi-minus</v-icon>
      </v-btn></v-toolbar
    >

    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :viewBox="vb"
      :key="skey"
      ref="svg1"
    >
      <title>{{ title }}</title>
      <linearGradient
        id="Dégradé_sans_nom_63"
        x1="390"
        y1="1055.25"
        x2="415"
        y2="1055.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset="0.37" stop-color="#fdfdfd" />
        <stop offset="0.51" stop-color="#f6f6f6" />
        <stop offset="0.6" stop-color="#ebebeb" />
        <stop offset="0.68" stop-color="#dadada" />
        <stop offset="0.75" stop-color="#c4c4c4" />
        <stop offset="0.8" stop-color="#a8a8a8" />
        <stop offset="0.86" stop-color="#888" />
        <stop offset="0.91" stop-color="#626262" />
        <stop offset="0.95" stop-color="#373737" />
        <stop offset="0.99" stop-color="#090909" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="Dégradé_sans_nom_65"
        x1="291"
        y1="288.58"
        x2="316"
        y2="288.58"
        gradientTransform="translate(86 892.83)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset="0.28" stop-color="#fdfdfd" />
        <stop offset="0.42" stop-color="#f5f5f5" />
        <stop offset="0.52" stop-color="#e8e8e8" />
        <stop offset="0.61" stop-color="#d5d5d5" />
        <stop offset="0.69" stop-color="#bdbdbd" />
        <stop offset="0.77" stop-color="#9f9f9f" />
        <stop offset="0.84" stop-color="#7c7c7c" />
        <stop offset="0.9" stop-color="#525252" />
        <stop offset="0.96" stop-color="#252525" />
        <stop offset="1" />
      </linearGradient>
      <rect id="base" class="cls-155" x="201.5" y="0" width="400" height="30" />
      <!-- <formations> -->
      <g v-if="formations">
        <rect
          v-for="formation in formations.filter((elm) => elm.depth > 0)"
          :key="'h' + formation.id"
          :id="formation.id"
          :class="'cls-' + (formation.formation_id % 14)"
          :style="
            'fill:' + formation.color + ';stroke-width:1;stroke:rgb(0,0,0)'
          "
          :y="formation.depthfrom * coef_v + 30"
          width="768"
          :height="formation.depth * coef_v"
          @mouseover="get_formation_Info"
          @mouseleave="title = null"
        />
        <!-- <text
          v-for="f in formations.filter((elm) => elm.depth > 50)"
          :key="'x' + f.formation_id"
          class="cls-131"
          :transform="
            'translate(2 ' +
            (f.depthfrom != null ? parseFloat(f.depthfrom * coef_v + 50) : 0) +
            ')'
          "
        >
          {{ (f.depthfrom ? f.depthfrom.toFixed(2) : "0") + " " + unit }}
        </text> -->
        <text
          v-for="formation in formations.filter((elm) => elm.depth > 100)"
          :key="'t' + formation.formation_id"
          class="cls-14"
          :transform="
            'translate(10 ' +
            (formation.depth
              ? parseFloat(
                  (formation.depth * coef_v) / 2 +
                    formation.depthfrom * coef_v +
                    30
                )
              : 0) +
            ')'
          "
        >
          {{ formation.formationname }}
        </text>
        <rect
          v-for="formation in formations.filter(
            (elm) => elm.depthfrom > 0 && elm.fluidtype != null
          )"
          :key="'fl' + formation.formation_id"
          :class="
            formation.fluidtype == 'Water'
              ? 'water'
              : formation.fluidtype == 'Oil' ||
                formation.fluidtype == 'Gas,Oil' ||
                formation.fluidtype == 'Oil,Gas'
              ? 'oil'
              : formation.fluidtype == 'Gas' ||
                formation.fluidtype == 'Gas,Water' ||
                formation.fluidtype == 'Water,Gas'
              ? 'gas'
              : 'gasoil'
          "
          x="600"
          :y="
            parseFloat(
              (formation.depth * coef_v) / 2 + formation.depthfrom * coef_v + 10
            )
          "
          width="150"
          :height="30"
        />
        <text
          v-for="formation in formations.filter(
            (elm) => elm.depthfrom > 0 && elm.fluidtype != null
          )"
          :key="'flc' + formation.formation_id"
          class="fluid_text"
          :transform="
            'translate(600 ' +
            (formation.depth
              ? parseFloat(
                  (formation.depth * coef_v) / 2 +
                    formation.depthfrom * coef_v +
                    30
                )
              : 0) +
            ')'
          "
        >
          {{ formation.fluidtype }}
        </text>
      </g>
      <g v-if="csgs">
        <!-- <casing annulus> -->
        <g
          v-for="csg in reverse_csgs.filter(
            (elm) => elm.tubular_id != 1 && elm.depthfrom == 0
          )"
          :key="'ann' + csg.id"
        >
          <!-- :class="'cls-' + (csg.id % 14)" -->
          <rect
            :id="csg.id"
            :class="'cls-11'"
            :x="csg.xl"
            :y="30"
            :width="coef_h * csg.max_od_in"
            :height="(csg.packer ? csg.packer : csg.depth) * coef_v + wc"
            v-if="csg.depth"
            @mouseover="get_annulus_Info"
            @mouseleave="title = null"
          />
        </g>
        <!-- <Well boar> -->
        <g
          v-for="csg2 in reverse_csgs.filter((elm) => elm.packer != null)"
          :key="'wb' + csg2.id"
        >
          <rect
            :id="csg2.id"
            :x="csg2.xl"
            :fill="'purple'"
            :y="csg2.packer * coef_v + 30 + wc"
            :width="coef_h * csg2.max_od_in"
            :height="(well.td - csg2.packer) * coef_v - wc"
            v-if="csg2.depth"
            @mouseover="title = 'Wellbore'"
            @mouseleave="title = null"
          />
        </g>
        <!-- <tubing> -->
        <g
          v-for="csg in csgs.filter((elm) => elm.tubular_id == 1)"
          :key="'tub' + csg.id"
        >
          <!-- <rect
            :id="'tub' + csg.id"
            class="cls-10"
            x="383"
            y="0"
            :width="25"
            :height="csg.depth * coef_v"
            :transform="
              'translate(785 ' +
              parseInt(csg.depth * coef_v + 30) +
              ') rotate(180)'
            "
            @mouseover="get_tubular_Info"
            @mouseleave="title = null"
          /> -->
          <rect
            :id="'tub_r' + csg.id"
            :x="401 - (coef_h * csg.max_od_in) / 2"
            y="30"
            :width="coef_h * csg.max_od_in"
            :height="csg.depth * coef_v"
            @mouseover="get_tubular_Info"
            @mouseleave="title = null"
          />
        </g>
        <!-- <casing> -->
        <g
          v-for="csg in csgs.filter((elm) => elm.tubular_id != 1)"
          :key="'csg' + csg.id"
        >
          <rect
            :id="csg.id"
            :x="csg.xl"
            :y="csg.depthfrom * coef_v + 30"
            :width="w"
            :height="csg.depth * coef_v"
            v-if="csg.depth"
            @mouseover="get_csg_Info"
            @mouseleave="title = null"
          />
          <rect
            :id="csg.id"
            :x="csg.xr"
            :y="csg.depthfrom * coef_v + 30"
            :width="w"
            :height="csg.depth * coef_v"
            v-if="csg.depth"
            @mouseover="get_csg_Info"
            @mouseleave="title = null"
          />

          <!-- <packer> -->
          <g v-for="packer in csg.packers_list" :key="'packer' + packer.id">
            <rect
              :id="packer.id"
              class="packer"
              :x="396 + w - (coef_h * csg.max_od_in) / 2"
              :y="packer.depth * coef_v + 30"
              :width="
                csg.size_packer -
                w +
                (csg.dl > packer.depth && csg.liner > 0
                  ? (csg.size_prec / 2) * coef_h - w - 2
                  : 0)
              "
              :height="wc"
              v-if="csg.xl && packer.depth"
              @mouseover="get_pck_Info"
              @mouseleave="title = null"
            />
            <rect
              :id="packer.id"
              class="packer"
              :x="
                396 +
                (coef_h * csg.max_od_in) / 2 -
                csg.size_packer +
                w -
                (csg.dl > packer.depth && csg.liner > 0
                  ? (csg.size_prec / 2) * coef_h - w - 2
                  : 0)
              "
              :y="packer.depth * coef_v + 30"
              :width="
                csg.size_packer -
                w +
                (csg.dl > packer.depth && csg.liner > 0
                  ? (csg.size_prec / 2) * coef_h - w
                  : 0)
              "
              :height="wc"
              v-if="csg.xr && packer.depth"
              @mouseover="get_pck_Info"
              @mouseleave="title = null"
            />
          </g>

          <polygon
            id="csc_base_r"
            class="cls-155"
            :points="
              ' ' +
              parseInt(csg.xl + w) +
              ' ' +
              parseInt(csg.depth * coef_v + csg.depthfrom * coef_v - w + 25) +
              ' ' +
              parseInt(csg.xl + w) +
              ' ' +
              parseInt(csg.depth * coef_v + csg.depthfrom * coef_v + w + 25) +
              ' ' +
              parseInt(csg.xl - wc) +
              ' ' +
              parseInt(csg.depth * coef_v + csg.depthfrom * coef_v + w + 25) +
              ' ' +
              parseInt(csg.xl + w) +
              ' ' +
              parseInt(csg.depth * coef_v + csg.depthfrom * coef_v - w + 25)
            "
            v-if="csg.xl && csg.depth"
          />
          <polygon
            id="csc_base"
            class="cls-155"
            :points="
              ' ' +
              parseInt(csg.xr + 2) +
              ' ' +
              parseInt(csg.depth * coef_v + csg.depthfrom * coef_v - w + 25) +
              ' ' +
              parseInt(csg.xr + 2) +
              ' ' +
              parseInt(csg.depth * coef_v + csg.depthfrom * coef_v + w + 25) +
              ' ' +
              parseInt(csg.xr + w + wc + 2) +
              ' ' +
              parseInt(csg.depth * coef_v + csg.depthfrom * coef_v + w + 25) +
              ' ' +
              parseInt(csg.xr + 2) +
              ' ' +
              parseInt(csg.depth * coef_v + csg.depthfrom * coef_v - w + 25)
            "
            v-if="csg.xr && csg.depth"
          />
        </g>
        <!-- <cement> -->
        <g
          v-for="csg in csgs.filter((elm) => elm.tubular_id > 1)"
          :key="'cem' + csg.id"
        >
          <rect
            :id="csg.id"
            class="cls-12"
            :x="next_csg_l(csg.id).x"
            :y="csg.topcement * coef_v + 30"
            :width="next_csg_l(csg.id).w"
            :height="(csg.depth - csg.topcement + csg.depthfrom) * coef_v"
            v-if="csg.xl && csg.topcement != null"
            @mouseover="get_cement_Info"
            @mouseleave="title = null"
          />
          <rect
            :id="csg.id"
            class="cls-12"
            :x="csg.xr + w"
            :y="csg.topcement * coef_v + 30"
            :width="next_csg_r(csg.id)"
            :height="(csg.depth - csg.topcement + csg.depthfrom) * coef_v"
            v-if="csg.xr && csg.topcement != null"
            @mouseover="get_cement_Info"
            @mouseleave="title = null"
          />
        </g>
      </g>
      <!-- <cement test> -->
      <g v-if="cement_test">
        <rect
          :id="cement.well_tubular_id"
          v-for="cement in cement_test.wellstest_tubulars"
          :key="'cem10' + cement.id"
          :class="'eval-1'"
          :x="next_csg_l(cement.well_tubular_id).x"
          :y="cement.depthfrom * coef_v + 30"
          :width="next_csg_l(cement.well_tubular_id).w"
          :height="cement.depth * coef_v"
          @mouseover="get_cement_log_Info"
          @mouseleave="title = null"
        />
        <rect
          :id="cement.well_tubular_id"
          v-for="cement in cement_test.wellstest_tubulars"
          :key="'cem10_inv' + cement.id"
          :class="'eval-1'"
          :x="cement.xr + w"
          :y="cement.depthfrom * coef_v + 30"
          :width="next_csg_r(cement.well_tubular_id)"
          :height="cement.depth * coef_v"
          @mouseover="get_cement_log_Info"
          @mouseleave="title = null"
        />
        <rect
          :id="cement.id"
          v-for="cement in cement_test.cement_details.filter(
            (elm) => elm.level_id
          )"
          :key="'cem11' + cement.id"
          :fill="cement.color"
          :x="next_csg_l(cement.well_tubular_id).x"
          :y="cement.depthfrom * coef_v + 30"
          :width="next_csg_l(cement.well_tubular_id).w"
          :height="cement.depth * coef_v"
          @mouseover="get_cement_res_Info"
          @mouseleave="title = null"
        />
        <rect
          :id="cement.id"
          v-for="cement in cement_test.cement_details.filter(
            (elm) => elm.level_id
          )"
          :key="'cemm12' + cement.id"
          :fill="cement.color"
          :x="cement.xr + w"
          :y="cement.depthfrom * coef_v + 30"
          :width="next_csg_r(cement.well_tubular_id)"
          :height="cement.depth * coef_v"
          @mouseover="get_cement_res_Info"
          @mouseleave="title = null"
        />
      </g>

      <g v-if="well_cements">
        <g
          v-for="tubular in well_cements.tubulars"
          :key="tubular.well_tubular_id"
        >
          <g v-if="tubular.xl">
            <rect
              :id="tubular.well_tubular_id"
              v-for="log in tubular.logs"
              :key="'cem15' + log.welltest_id"
              :class="'eval-1'"
              :x="next_csg_l(tubular.well_tubular_id).x"
              :y="log.depthfrom * coef_v + 30"
              :width="next_csg_l(tubular.well_tubular_id).w"
              :height="log.depth * coef_v"
              @mouseover="get_cement_log_Info"
              @mouseleave="title = null"
            />
          </g>
          <g v-if="tubular.xr">
            <rect
              :id="tubular.well_tubular_id"
              v-for="log in tubular.logs"
              :key="'cem15_inv' + log.welltest_id"
              :class="'eval-1'"
              :x="tubular.xr + w"
              :y="log.depthfrom * coef_v + 30"
              :width="next_csg_r(tubular.well_tubular_id)"
              :height="log.depth * coef_v"
              @mouseover="get_cement_log_Info"
              @mouseleave="title = null"
            />
          </g>
          <g v-if="tubular.xl">
            <rect
              :id="cement.id"
              v-for="cement in tubular.cement_details"
              :key="'cem16' + cement.id"
              :fill="cement.color"
              :x="next_csg_l(tubular.well_tubular_id).x"
              :y="cement.depthfrom * coef_v + 30"
              :width="next_csg_l(tubular.well_tubular_id).w"
              :height="cement.depth * coef_v"
              @mouseover="get_cement_res_Info"
              @mouseleave="title = null"
            />
          </g>
          <g v-if="tubular.xr">
            <rect
              :id="cement.id"
              v-for="cement in tubular.cement_details"
              :key="'cem16_inv' + cement.id"
              :fill="cement.color"
              :x="tubular.xr + w"
              :y="cement.depthfrom * coef_v + 30"
              :width="next_csg_r(tubular.well_tubular_id)"
              :height="cement.depth * coef_v"
              @mouseover="get_cement_res_Info"
              @mouseleave="title = null"
            />
          </g>
        </g>
      </g>
      <g v-if="corrosion_test">
        <rect
          :id="corrosion.well_tubular_id"
          v-for="corrosion in corrosion_test.wellstest_tubulars.filter(
            (elm) => elm.xl != null
          )"
          :key="'cor10' + corrosion.id"
          :class="'eval-1'"
          :x="corrosion.xl"
          :y="corrosion.depthfrom * coef_v + 30"
          :width="corrosion.tubular_id == 1 ? corrosion.width : w"
          :height="corrosion.depth * coef_v"
          @mouseover="get_corrosion_log_Info"
          @mouseleave="title = null"
        />
        <rect
          :id="corrosion.well_tubular_id"
          v-for="corrosion in corrosion_test.wellstest_tubulars.filter(
            (elm) => elm.xr != null
          )"
          :key="'cor10_inv' + corrosion.id"
          :class="'eval-1'"
          :x="corrosion.xr"
          :y="corrosion.depthfrom * coef_v + 30"
          :width="corrosion.tubular_id == 1 ? corrosion.width : w"
          :height="corrosion.depth * coef_v"
          @mouseover="get_corrosion_log_Info"
          @mouseleave="title = null"
        />
        <rect
          :id="corrosion.id"
          v-for="corrosion in corrosion_test.corrosion_details.filter(
            (elm) => elm.xl != null && elm.evaluation
          )"
          :key="'cor11' + corrosion.id"
          :fill="corrosion.color"
          :x="corrosion.xl"
          :y="corrosion.depthfrom * coef_v + 30"
          :width="corrosion.tubular_id == 1 ? corrosion.width : w"
          :height="corrosion.depth * coef_v"
          @mouseover="get_corrosion_res_Info"
          @mouseleave="title = null"
        />
        <rect
          :id="corrosion.id"
          v-for="corrosion in corrosion_test.corrosion_details.filter(
            (elm) => elm.xr != null && elm.evaluation
          )"
          :key="'corr12' + corrosion.id"
          :fill="corrosion.color"
          :x="corrosion.xr"
          :y="corrosion.depthfrom * coef_v + 30"
          :width="corrosion.tubular_id == 1 ? corrosion.width : w"
          :height="corrosion.depth * coef_v"
          @mouseover="get_corrosion_res_Info"
          @mouseleave="title = null"
        />
        <g>
          <rect
            :id="tub_default.id"
            v-for="tub_default in corrosion_test.corrosion_details.filter(
              (elm) => elm.xl != null && elm.default_id == 1
            )"
            :key="'def_r' + tub_default.id"
            :class="'cls-11'"
            :x="tub_default.xl"
            :y="tub_default.depthfrom * coef_v + 30"
            :width="tub_default.tubular_id == 1 ? tub_default.width : w"
            :height="tub_default.depth * coef_v"
            @mouseover="get_default_Info"
            @mouseleave="title = null"
          />
          <rect
            :id="tub_default.id"
            v-for="tub_default in corrosion_test.corrosion_details.filter(
              (elm) => elm.xr != null && elm.default_id != null
            )"
            :key="'def' + tub_default.id"
            :style="
              tub_default.default_id != 1
                ? 'fill:' +
                  tub_default.default_color +
                  ';stroke-width:1;stroke:rgb(0,0,0)'
                : ''
            "
            :class="tub_default.default_id == 1 ? 'cls-11' : ''"
            :x="tub_default.xr"
            :y="tub_default.depthfrom * coef_v + 30"
            :width="tub_default.tubular_id == 1 ? tub_default.width : w"
            :height="tub_default.depth * coef_v"
            @mouseover="get_default_Info"
            @mouseleave="title = null"
          />

          <polygon
            :id="tub_default.id"
            v-for="tub_default in corrosion_test.corrosion_details.filter(
              (elm) =>
                elm.xr != null && elm.default_id != null && elm.default_id != 1
            )"
            :key="'def_p' + tub_default.id"
            :style="
              'fill:' +
              tub_default.default_color +
              ';stroke-width:1;stroke:rgb(0,0,0)'
            "
            :points="
              parseInt(tub_default.xr + 30 + w) +
              ' ' +
              parseInt(
                tub_default.depthfrom * coef_v +
                  33 +
                  (tub_default.depth * coef_v) / 2
              ) +
              ', ' +
              parseInt(tub_default.xr + w) +
              ' ' +
              parseInt(
                tub_default.depthfrom * coef_v +
                  45 +
                  (tub_default.depth * coef_v) / 2
              ) +
              ', ' +
              parseInt(tub_default.xr + w) +
              ' ' +
              parseInt(
                tub_default.depthfrom * coef_v +
                  21 +
                  (tub_default.depth * coef_v) / 2
              )
            "
            @mouseover="get_default_Info"
            @mouseleave="title = null"
          />
        </g>
      </g>

      <g v-if="well_corrosions">
        <g
          v-for="tubular in well_corrosions.tubulars"
          :key="tubular.well_tubular_id"
        >
          <g v-if="tubular.xl">
            <rect
              :id="tubular.well_tubular_id"
              v-for="log in tubular.logs"
              :key="'cor15' + log.welltest_id"
              :class="'eval-1'"
              :x="tubular.xl"
              :y="log.depthfrom * coef_v + 30"
              :width="tubular.tubular_id == 1 ? tubular.width : w"
              :height="log.depth * coef_v"
              @mouseover="get_corrosion_log_Info"
              @mouseleave="title = null"
            />
          </g>
          <g v-if="tubular.xr">
            <rect
              :id="tubular.well_tubular_id"
              v-for="log in tubular.logs"
              :key="'cor15_inv' + log.welltest_id"
              :class="'eval-1'"
              :x="tubular.xr"
              :y="log.depthfrom * coef_v + 30"
              :width="tubular.tubular_id == 1 ? tubular.width : w"
              :height="log.depth * coef_v"
              @mouseover="get_corrosion_log_Info"
              @mouseleave="title = null"
            />
          </g>
          <g v-if="tubular.xl">
            <rect
              :id="corrosion.id"
              v-for="corrosion in tubular.corrosion_details.filter(
                (elm) => elm.evaluation != null
              )"
              :key="'cor16' + corrosion.id"
              :fill="corrosion.color"
              :x="tubular.xl"
              :y="corrosion.depthfrom * coef_v + 30"
              :width="tubular.tubular_id == 1 ? tubular.width : w"
              :height="corrosion.depth * coef_v"
              @mouseover="get_corrosion_res_Info"
              @mouseleave="title = null"
            />
          </g>
          <g v-if="tubular.xr">
            <rect
              :id="corrosion.id"
              v-for="corrosion in tubular.corrosion_details.filter(
                (elm) => elm.evaluation != null
              )"
              :key="'cor16_inv' + corrosion.id"
              :fill="corrosion.color"
              :x="tubular.xr"
              :y="corrosion.depthfrom * coef_v + 30"
              :width="tubular.tubular_id == 1 ? tubular.width : w"
              :height="corrosion.depth * coef_v"
              @mouseover="get_corrosion_res_Info"
              @mouseleave="title = null"
            />
          </g>

          <g>
            <rect
              :id="tub_default.id"
              v-for="tub_default in tubular.corrosion_details.filter(
                (elm) => elm.default_id == 1
              )"
              :key="'def_r' + tub_default.id"
              :class="'cls-11'"
              :x="tubular.xl"
              :y="tub_default.depthfrom * coef_v + 30"
              :width="tub_default.tubular_id == 1 ? tubular.width : w"
              :height="tub_default.depth * coef_v"
              @mouseover="get_default_Info"
              @mouseleave="title = null"
            />
            <rect
              :id="tub_default.id"
              v-for="tub_default in tubular.corrosion_details.filter(
                (elm) => elm.default_id != null
              )"
              :key="'def' + tub_default.id"
              :style="
                tub_default.default_id != 1
                  ? 'fill:' +
                    tub_default.default_color +
                    ';stroke-width:1;stroke:rgb(0,0,0)'
                  : ''
              "
              :class="tub_default.default_id == 1 ? 'cls-11' : ''"
              :x="tubular.xr"
              :y="tub_default.depthfrom * coef_v + 30"
              :width="tub_default.tubular_id == 1 ? tubular.width : w"
              :height="tub_default.depth * coef_v"
              @mouseover="get_default_Info"
              @mouseleave="title = null"
            />

            <polygon
              :id="tub_default.id"
              v-for="tub_default in tubular.corrosion_details.filter(
                (elm) => elm.default_id != null && elm.default_id != 1
              )"
              :key="'def_p' + tub_default.id"
              :style="
                'fill:' +
                tub_default.default_color +
                ';stroke-width:1;stroke:rgb(0,0,0)'
              "
              :points="
                parseInt(tubular.xr + 30 + w) +
                ' ' +
                parseInt(
                  tub_default.depthfrom * coef_v +
                    33 +
                    (tub_default.depth * coef_v) / 2
                ) +
                ', ' +
                parseInt(tubular.xr + w) +
                ' ' +
                parseInt(
                  tub_default.depthfrom * coef_v +
                    45 +
                    (tub_default.depth * coef_v) / 2
                ) +
                ', ' +
                parseInt(tubular.xr + w) +
                ' ' +
                parseInt(
                  tub_default.depthfrom * coef_v +
                    21 +
                    (tub_default.depth * coef_v) / 2
                )
              "
              @mouseover="get_default_Info"
              @mouseleave="title = null"
            />
          </g>
        </g>
      </g>
      <g v-if="risks_list">
        <rect
          :id="risk.id"
          v-for="risk in risks_list"
          :key="'risk' + risk.id"
          :style="'fill:' + risk.color + ';stroke-width:1;stroke:rgb(0,0,0)'"
          :x="430 + csgs.length * (w + wc)"
          :y="risk.depthfrom * coef_v + 30"
          :width="w"
          :height="risk.depth * coef_v"
          @mouseover="get_risk_Info"
          @mouseleave="title = null"
        />
        <rect
          :id="risk.id"
          v-for="risk in risks_list"
          :key="'risk_r' + risk.id"
          :x="480 + csgs.length * (w + wc)"
          :y="risk.depthfrom * coef_v + 30 + (risk.depth * coef_v) / 2"
          :width="100"
          :height="6"
        />
        <polygon
          :id="risk.id"
          v-for="risk in risks_list"
          :key="'risk_p' + risk.id"
          class="cls-155"
          :points="
            '600 ' +
            parseInt(risk.depthfrom * coef_v + 33 + (risk.depth * coef_v) / 2) +
            ', 630 ' +
            parseInt(risk.depthfrom * coef_v + 45 + (risk.depth * coef_v) / 2) +
            ', 630 ' +
            parseInt(risk.depthfrom * coef_v + 21 + (risk.depth * coef_v) / 2)
          "
        />
      </g>
    </svg>

    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item @click="zoom_in">
            <v-list-item-title>Zoom In</v-list-item-title>
          </v-list-item>
          <v-list-item @click="zoom_out">
            <v-list-item-title>Zoom Out</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-card>
</template>

<script>
export default {
  name: "MyWellComponent",
  props: {
    formations: Array,
    csgs: Array,
    corrosion_test: Object,
    cement_test: Object,
    actual_corrosions: Array,
    actual_cements: Array,
    unit: String,
    risks_list: Array,
    well: Object,
  },
  data: () => ({
    vb: "0 0 770 2200",
    l: 770,
    h: 2200,
    showMenu: false,
    selectedItem: 0,
    x: 0,
    y: 0,
    w: 10,
    wc: 15,
    skey: 100,
    top: 0,
    left: 0,
    title: "Well",
    max_h: 0,
    coef_v: 0,
    max_od_in: 0,
    coef_h: 0,
    h_card: 600,
    cement_details: [],
    well_corrosions: null,
    well_cements: null,
  }),
  created() {
    if (this.formations) {
      let n = this.formations.length - 14;
      if (n > 0) {
        this.h_card = this.h_card + n * 50;
        this.h = this.h + n * 50;
      }
    }
    this.vb = "0 " + "0 " + this.l + " " + this.h;
    this.max_h = this.well.td;
    this.coef_v = (this.h - 200) / this.max_h;
    if (this.csgs) {
      //this.max_h = Math.max(...this.csgs.map((elm) => elm.depth));
      this.max_od_in = Math.max(...this.csgs.map((elm) => elm.max_od_in));
      this.coef_h = 310 / this.max_od_in;
    }

    if (this.csgs) {
      let l = this.csgs; //.filter((elm) => elm.tubular_id > "1");//////////////////////////////////////////////////////////////////
      for (let index = 0; index < l.length; index++) {
        const element = l[index];
        let depthfrom_prec = null;
        element.liner = 0;
        element.xl = 396 - (this.coef_h * element.max_od_in) / 2;
        element.xr = 396 + (this.coef_h * element.max_od_in) / 2;
        if (index > 0) {
          let m = Math.max(
            ...this.csgs
              .filter(
                (elm) => elm.csg_order < element.csg_order && elm.depthfrom == 0
              )
              .map((elm) => elm.csg_order)
          );
          let liners = this.csgs.filter(
            (elm) =>
              elm.csg_order < element.csg_order &&
              elm.csg_order > m &&
              elm.depthfrom > 0
          );
          element.liner = liners.length;
          element.size_prec = liners.reduce(
            (a, b) => a + (b["max_od_in"] || 0),
            0
          );
          //////----------------------------/---
          let v = l.filter(
            (elm) =>
              elm.csg_order < element.csg_order &&
              elm.depthto >= element.depthto
          );
          let val = l[index - 1].max_od_in;
          let order = 0;
          v.forEach((x) => {
            if (x.csg_order >= order) val = x.max_od_in;
          });
          depthfrom_prec = l[index - 1].depthfrom;
          element.size_packer = (this.coef_h * (element.max_od_in - val)) / 2;
        }
        if (depthfrom_prec > 0) {
          element.dl = depthfrom_prec;
        }
      }
    }

    if (this.cement_test) {
      for (
        let index = 0;
        index < this.cement_test.wellstest_tubulars.length;
        index++
      ) {
        const element = this.cement_test.wellstest_tubulars[index];

        let i = this.csgs.findIndex((elm) => elm.id == element.well_tubular_id);
        if (i >= 0) {
          element.xl = 396 - (this.coef_h * this.csgs[i].max_od_in) / 2;
          element.xr = 396 + (this.coef_h * this.csgs[i].max_od_in) / 2;

          element.depthfrom =
            this.cement_test.logfrom > this.csgs[i].depthfrom
              ? this.cement_test.logfrom
              : this.csgs[i].depthfrom;
          element.depthto =
            this.cement_test.logto < this.csgs[i].depthto
              ? this.cement_test.logto
              : this.csgs[i].depthto;
          element.depth = element.depthto - element.depthfrom;
        }
      }

      for (
        let index = 0;
        index < this.cement_test.cement_details.length;
        index++
      ) {
        const element = this.cement_test.cement_details[index];

        let i = this.csgs.findIndex((elm) => elm.id == element.well_tubular_id);
        if (i >= 0) {
          element.xl = 396 - (this.coef_h * this.csgs[i].max_od_in) / 2;
          element.xr = 396 + (this.coef_h * this.csgs[i].max_od_in) / 2;
        }
      }
    }
    if (this.actual_cements) {
      if (this.actual_cements.length > 0)
        this.well_cements = this.actual_cements[0];
      if (this.well_cements)
        for (
          let index = 0;
          index < this.well_cements.tubulars.length;
          index++
        ) {
          const element = this.well_cements.tubulars[index];

          let i = this.csgs.findIndex(
            (elm) => elm.id == element.well_tubular_id
          );
          if (i >= 0) {
            if (element.tubular_id == 1) {
              element.xl = 401 - (this.coef_h * this.csgs[i].max_od_in) / 2;
              element.width = this.coef_h * this.csgs[i].max_od_in;
            } else {
              element.xl = 396 - (this.coef_h * this.csgs[i].max_od_in) / 2;
              element.xr = 396 + (this.coef_h * this.csgs[i].max_od_in) / 2;
            }
            element.logs = [];
            element.logRanges.forEach((log) => {
              let l = {};
              l.welltest_id = log.welltest_id;
              l.depthfrom =
                log.logfrom > this.csgs[i].depthfrom
                  ? log.logfrom
                  : this.csgs[i].depthfrom;
              l.depthto =
                log.logto < this.csgs[i].depthto
                  ? log.logto
                  : this.csgs[i].depthto;
              l.depth = l.depthto - l.depthfrom;
              element.logs.push(l);
            });
            element.cement_details = element.cementRanges;
          }
        }
    }
    if (this.corrosion_test) {
      for (
        let index = 0;
        index < this.corrosion_test.wellstest_tubulars.length;
        index++
      ) {
        const element = this.corrosion_test.wellstest_tubulars[index];

        let i = this.csgs.findIndex((elm) => elm.id == element.well_tubular_id);
        if (i >= 0) {
          if (element.tubular_id == 1) {
            element.xl = 401 - (this.coef_h * this.csgs[i].max_od_in) / 2;
            element.width = this.coef_h * this.csgs[i].max_od_in;
          } else {
            element.xl = 396 - (this.coef_h * this.csgs[i].max_od_in) / 2;
            element.xr = 396 + (this.coef_h * this.csgs[i].max_od_in) / 2;
          }
          element.depthfrom =
            this.corrosion_test.logfrom > this.csgs[i].depthfrom
              ? this.corrosion_test.logfrom
              : this.csgs[i].depthfrom;
          element.depthto =
            this.corrosion_test.logto < this.csgs[i].depthto
              ? this.corrosion_test.logto
              : this.csgs[i].depthto;
          element.depth = element.depthto - element.depthfrom;
        }
      }
      for (
        let index = 0;
        index < this.corrosion_test.corrosion_details.length;
        index++
      ) {
        const element = this.corrosion_test.corrosion_details[index];

        let i = this.csgs.findIndex((elm) => elm.id == element.well_tubular_id);
        if (i >= 0) {
          if (element.tubular_id == 1) {
            element.xl = 401 - (this.coef_h * this.csgs[i].max_od_in) / 2;
            element.width = this.coef_h * this.csgs[i].max_od_in;
          } else {
            element.xl = 396 - (this.coef_h * this.csgs[i].max_od_in) / 2;
            element.xr = 396 + (this.coef_h * this.csgs[i].max_od_in) / 2;
          }
        }
      }
    }

    if (this.actual_corrosions) {
      if (this.actual_corrosions.length > 0)
        this.well_corrosions = this.actual_corrosions[0];
      if (this.well_corrosions)
        for (
          let index = 0;
          index < this.well_corrosions.tubulars.length;
          index++
        ) {
          const element = this.well_corrosions.tubulars[index];

          let i = this.csgs.findIndex(
            (elm) => elm.id == element.well_tubular_id
          );
          if (i >= 0) {
            if (element.tubular_id == 1) {
              element.xl = 401 - (this.coef_h * this.csgs[i].max_od_in) / 2;
              element.width = this.coef_h * this.csgs[i].max_od_in;
            } else {
              element.xl = 396 - (this.coef_h * this.csgs[i].max_od_in) / 2;
              element.xr = 396 + (this.coef_h * this.csgs[i].max_od_in) / 2;
            }
            element.logs = [];
            element.logRanges.forEach((log) => {
              let l = {};
              l.welltest_id = log.welltest_id;
              l.depthfrom =
                log.logfrom > this.csgs[i].depthfrom
                  ? log.logfrom
                  : this.csgs[i].depthfrom;
              l.depthto =
                log.logto < this.csgs[i].depthto
                  ? log.logto
                  : this.csgs[i].depthto;
              l.depth = l.depthto - l.depthfrom;
              element.logs.push(l);
            });
            element.corrosion_details = element.corrosionRanges;
          }
        }
    }

    if (this.$refs.svg1) {
      const el = this.$refs.svg1;
      const rect = el.getBoundingClientRect();
      this.top = rect.top;
      this.bottom = rect.bottom;
      this.left = rect.left;
      this.right = rect.right;
      this.width = rect.width;
      this.height = rect.height;
    }

    this.skey++;
  },

  mounted() {},
  computed: {
    reverse_csgs() {
      return this.csgs.slice().reverse();
    },
  },
  methods: {
    next_csg_l(csg_id) {
      let w = this.wc,
        x;
      let i = this.csgs.findIndex((elm) => elm.id == csg_id);
      if (i >= 0) {
        let csg = this.csgs[i];
        x = csg.xl - this.wc;
        let j = this.csgs.findIndex(
          (elm) => elm.csg_order == csg.csg_order + 1
        );
        if (j >= 0) {
          x = this.csgs[j].xl + this.w;
          w = csg.xl - this.csgs[j].xl - this.w;
        }
      }
      return { x: x, w: w };
    },
    next_csg_r(csg_id) {
      let w = this.wc;

      let i = this.csgs.findIndex((elm) => elm.id == csg_id);
      if (i >= 0) {
        let csg = this.csgs[i];
        let j = this.csgs.findIndex(
          (elm) => elm.csg_order == csg.csg_order + 1
        );
        if (j >= 0) {
          w = this.csgs[j].xr - csg.xr - this.w;
        }
      }
      return w;
    },
    get_formation_Info(e) {
      let i = this.formations.findIndex((elm) => elm.id == e.target.id);
      if (i >= 0)
        this.title =
          this.formations[i].formationname +
          "\n" +
          "From : " +
          this.formations[i].depthfrom +
          " " +
          this.unit +
          "\n" +
          "To : " +
          this.formations[i].depthto +
          " " +
          this.unit +
          "\n" +
          "TVD : " +
          (this.formations[i].tvd ? this.formations[i].tvd : "") +
          " " +
          this.unit +
          "\n" +
          "Fluid Type : " +
          (this.formations[i].fluidtype ? this.formations[i].fluidtype : "ND") +
          "\n" +
          "Formation Pressure : " +
          (this.formations[i].formationpr
            ? this.formations[i].formationpr +
              " " +
              this.$store.state.options[0].pressure
            : "ND") +
          "\n" +
          "Surface Pressure : " +
          (this.formations[i].surfacepr
            ? this.formations[i].surfacepr +
              " " +
              this.$store.state.options[0].pressure
            : "ND");
      else this.title = "";
    },
    get_csg_Info(e) {
      let i = this.csgs.findIndex((elm) => elm.id == e.target.id);
      if (i >= 0)
        this.title =
          this.csgs[i].item +
          "\n" +
          "Size : " +
          this.csgs[i].sizes_in +
          "\n" +
          "Weight : " +
          this.csgs[i].weights_lbm +
          "\n" +
          "Grade : " +
          this.csgs[i].grades +
          "\n" +
          "From : " +
          this.csgs[i].depthfrom +
          " " +
          this.unit +
          "\n" +
          "To : " +
          this.csgs[i].depthto +
          " " +
          this.unit +
          "\nTop of cement : " +
          (this.csgs[i].topcement != null
            ? this.csgs[i].topcement + " " + this.unit
            : "None") +
          (this.csgs[i].packer != null
            ? "\nPacker Position : " + this.csgs[i].packer + " " + this.unit
            : "");
      else this.title = "";
    },

    get_annulus_Info(e) {
      let i = this.csgs.findIndex((elm) => elm.id == e.target.id);
      if (i >= 0)
        this.title =
          this.csgs[i].annulus +
          "\n" +
          "Volume : " +
          this.csgs[i].volume +
          " " +
          this.$store.state.options[0].volume +
          "\n" +
          "MAASP : " +
          (this.csgs[i].actual_maasp ? this.csgs[i].actual_maasp : "") +
          " " +
          this.$store.state.options[0].pressure +
          "\n" +
          "MAOP : " +
          this.csgs[i].maop +
          " " +
          this.$store.state.options[0].pressure;
      else this.title = "";
    },
    get_pck_Info(e) {
      for (let index = 0; index < this.csgs.length; index++) {
        const element = this.csgs[index];
        let i = element.packers_list.findIndex((elm) => elm.id == e.target.id);
        if (i >= 0) {
          this.title =
            element.item +
            " packer " +
            "\n" +
            "Position : " +
            element.packers_list[i].depth +
            " " +
            this.unit;
          break;
        } else this.title = "";
      }
    },
    get_cement_Info(e) {
      let i = this.csgs.findIndex((elm) => elm.id == e.target.id);
      if (i >= 0)
        this.title =
          this.csgs[i].item +
          " cement" +
          "\n" +
          "TOC : " +
          this.csgs[i].topcement +
          " " +
          this.unit;
      else this.title = "";
    },
    get_cement_log_Info(e) {
      if (this.cement_test) {
        let i = this.cement_test.wellstest_tubulars.findIndex(
          (elm) => elm.well_tubular_id == e.target.id
        );
        if (i >= 0)
          this.title =
            this.cement_test.wellstest_tubulars[i].item +
            " cement logging " +
            "\nFrom : " +
            this.cement_test.cement_details[i].depthfrom +
            " " +
            this.unit +
            "\nTo : " +
            this.cement_test.cement_details[i].depthto +
            " " +
            this.unit;
        else this.title = "";
      } else if (this.well_cements) {
        let i = this.well_cements.tubulars.findIndex(
          (elm) => elm.well_tubular_id == e.target.id
        );
        if (i >= 0) {
          let s = "";
          this.well_cements.tubulars[i].logs.forEach((log) => {
            s =
              s +
              " \nFrom : " +
              log.depthfrom +
              " " +
              this.unit +
              "\nTo : " +
              log.depthto +
              " " +
              this.unit;
          });
          this.title =
            this.well_cements.tubulars[i].item + " cement logging " + s;
        } else this.title = "";
      }
    },
    get_cement_res_Info(e) {
      if (this.cement_test) {
        let i = this.cement_test.cement_details.findIndex(
          (elm) => elm.id == e.target.id
        );
        if (i >= 0)
          this.title =
            this.cement_test.cement_details[i].sizes +
            " " +
            this.cement_test.cement_details[i].level +
            " Cement" +
            "\nFrom : " +
            this.cement_test.cement_details[i].depthfrom +
            " " +
            this.unit +
            "\nTo : " +
            this.cement_test.cement_details[i].depthto +
            " " +
            this.unit;
        else this.title = "";
      } else if (this.well_cements) {
        for (
          let index = 0;
          index < this.well_cements.tubulars.length;
          index++
        ) {
          const element = this.well_cements.tubulars[index];

          let i = element.cement_details.findIndex(
            (elm) => elm.id == e.target.id
          );
          if (i >= 0) {
            this.title =
              element.item +
              " " +
              element.cement_details[i].level +
              " Cement" +
              "\nFrom : " +
              element.cement_details[i].depthfrom +
              " " +
              this.unit +
              "\nTo : " +
              element.cement_details[i].depthto +
              " " +
              this.unit;
            break;
          } else this.title = "";
        }
      }
    },
    get_default_Info(e) {
      if (this.corrosion_test) {
        let i = this.corrosion_test.corrosion_details.findIndex(
          (elm) => elm.id == e.target.id
        );
        if (i >= 0)
          this.title =
            this.corrosion_test.corrosion_details[i].sizes +
            " " +
            this.corrosion_test.corrosion_details[i].default_name +
            "\nFrom : " +
            this.corrosion_test.corrosion_details[i].depthfrom +
            " " +
            this.unit +
            "\nTo : " +
            this.corrosion_test.corrosion_details[i].depthto +
            " " +
            this.unit;
        else this.title = "";
      }
      if (this.well_corrosions) {
        for (
          let index = 0;
          index < this.well_corrosions.tubulars.length;
          index++
        ) {
          const element = this.well_corrosions.tubulars[index];

          let i = element.corrosion_details.findIndex(
            (elm) => elm.id == e.target.id
          );
          if (i >= 0) {
            this.title =
              element.item +
              " " +
              element.corrosion_details[i].default_name +
              "\nFrom : " +
              element.corrosion_details[i].depthfrom +
              " " +
              this.unit +
              "\nTo : " +
              element.corrosion_details[i].depthto +
              " " +
              this.unit;
            break;
          } else this.title = "";
        }
      }
    },
    get_corrosion_log_Info(e) {
      if (this.corrosion_test) {
        let i = this.corrosion_test.wellstest_tubulars.findIndex(
          (elm) => elm.well_tubular_id == e.target.id
        );
        if (i >= 0)
          this.title =
            this.corrosion_test.wellstest_tubulars[i].item +
            " corrosion logging " +
            "% \nFrom : " +
            this.corrosion_test.wellstest_tubulars[i].depthfrom +
            " " +
            this.unit +
            "\nTo : " +
            this.corrosion_test.wellstest_tubulars[i].depthto +
            " " +
            this.unit;
        else this.title = "";
      } else if (this.well_corrosions) {
        let i = this.well_corrosions.tubulars.findIndex(
          (elm) => elm.well_tubular_id == e.target.id
        );
        if (i >= 0) {
          let s = "";
          this.well_corrosions.tubulars[i].logs.forEach((log) => {
            s =
              s +
              " \nFrom : " +
              log.depthfrom +
              " " +
              this.unit +
              "\nTo : " +
              log.depthto +
              " " +
              this.unit;
          });
          this.title =
            this.well_corrosions.tubulars[i].item + " corrosion logging" + s;
        } else this.title = "";
      }
    },
    get_corrosion_res_Info(e) {
      if (this.corrosion_test) {
        let i = this.corrosion_test.corrosion_details.findIndex(
          (elm) => elm.id == e.target.id
        );
        if (i >= 0)
          this.title =
            this.corrosion_test.corrosion_details[i].sizes +
            " corrosion " +
            this.corrosion_test.corrosion_details[i].evaluation +
            "% \nFrom : " +
            this.corrosion_test.corrosion_details[i].depthfrom +
            " " +
            this.unit +
            "\nTo : " +
            this.corrosion_test.corrosion_details[i].depthto +
            " " +
            this.unit;
        else this.title = "";
      } else if (this.well_corrosions) {
        for (
          let index = 0;
          index < this.well_corrosions.tubulars.length;
          index++
        ) {
          const element = this.well_corrosions.tubulars[index];

          let i = element.corrosion_details.findIndex(
            (elm) => elm.id == e.target.id
          );
          if (i >= 0) {
            this.title =
              element.item +
              " corrosion " +
              element.corrosion_details[i].evaluation +
              "% \nFrom : " +
              element.corrosion_details[i].depthfrom +
              " " +
              this.unit +
              "\nTo : " +
              element.corrosion_details[i].depthto +
              " " +
              this.unit;
            break;
          } else this.title = "";
        }
      }
    },
    get_risk_Info(e) {
      let i = this.risks_list.findIndex((elm) => elm.id == e.target.id);
      if (i >= 0)
        this.title =
          this.risks_list[i].string +
          " " +
          (this.risks_list[i].level ? this.risks_list[i].level : " undefined") +
          " risk " +
          "\nFrom : " +
          this.risks_list[i].depthfrom +
          " " +
          this.unit +
          "\nTo : " +
          this.risks_list[i].depthto +
          " " +
          this.unit;
      else this.title = "";
    },
    get_tubular_Info() {
      let i = this.csgs.findIndex((elm) => elm.tubular_id == 1);
      if (i >= 0)
        this.title =
          "Production Tubing \nDepth : " +
          this.csgs[i].depth +
          " " +
          this.unit +
          "\n" +
          "Size : " +
          this.csgs[i].sizes_in +
          "\n" +
          "Weight : " +
          this.csgs[i].weights_lbm +
          "\n" +
          "Grade : " +
          this.csgs[i].grades +
          "\n" +
          "Volume : " +
          this.csgs[i].volume +
          " " +
          this.$store.state.options[0].volume;
      else this.title = "";
    },

    zoom_in() {
      this.l = this.l - 10;
      this.vb = "0 " + "0 " + this.l + " " + this.h;
      this.skey++;
    },
    zoom_out() {
      this.l = this.l + 10;
      this.vb = "0 " + "0 " + this.l + " " + this.h;
      this.skey++;
    },
    cylinder_svg(str, left, height) {
      var svgn = "http://www.w3.org/2000/svg";
      var g = document.createElementNS(svgn, "g");
      var path = document.createElementNS(svgn, "path");
      let th = height * Math.cos((0.25 * Math.PI) / 2);
      let d =
        "M " +
        left +
        ",4.64 a 16,4.64 0,0,0 32 0 a 16,4.64 0,0,0 -32 0 l 0," +
        th +
        " a 16,4.64 0,0,0 32 0 l 0,-" +
        th;
      path.setAttributeNS(null, "fill", "gray");
      path.setAttributeNS(null, "stroke", "black");
      path.setAttributeNS(null, "d", d);
      g.appendChild(path);
      g.appendChild(this.text_svg(str, left));

      return g;
    },
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
  },
};
</script>

<style scoped>
rect {
  -webkit-transition: all 150ms;
  -moz-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
}
rect:hover {
  opacity: 0.85;
}
.land {
  fill-opacity: 1;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.cls-0 {
  fill: #8a68b9;
}
.cls-1 {
  fill: #f4a39d;
}
.cls-2 {
  fill: #a5f9d7;
}
.cls-3 {
  fill: #f2ce7c;
}
.cls-4 {
  fill: #adcbf7;
}
.cls-5 {
  fill: #a1f7b3;
}
.cls-6 {
  fill: #a4e0ea;
}
.cls-7 {
  fill: #eaea8c;
}
.cls-8 {
  fill: #b1df08;
}
.cls-9 {
  fill: #13a3e7;
}
.cls-10 {
  fill: #af0d76;
}
.cls-19 {
  fill: url(#Dégradé_sans_nom_63);
}
.cls-20 {
  fill: url(#Dégradé_sans_nom_65);
}
.cls-11 {
  fill: #fff;
}
.cls-12 {
  fill: #a5a0a0;
}
.cls-13 {
  fill: #a9518a;
}
.cls-155 {
  fill: #0b0b0b;
}
.cls-131 {
  font-size: 22px;
  font-family: MyriadPro-Regular, Myriad Pro;
}
.cls-14 {
  font-size: 28px;
  font-family: MyriadPro-Regular, Myriad Pro;
}
.cls-14 {
  fill: #050505;
}
.cls-15 {
  fill: #e61616;
}
.cls-16 {
  fill: #b5a8e0;
}
.packer {
  fill: #1848af;
}
.fluid_text {
  font-size: 22px;
}

.water {
  color: rgb(222, 241, 13) !important;
  fill: #3875f9;
}
.gasoil {
  fill: #e2b40b;
}
.gas {
  fill: #ece40e;
}
.gaswater {
  fill: #ece40e;
}
.oil {
  fill: #e2b40b;
}
.eval-1 {
  fill: #2bd81f;
}
.eval-2 {
  fill: #f1870e;
}
.eval-3 {
  fill: #f11d11;
}
.eval-4 {
  fill: #5c5a55;
}
.eval-5 {
  fill: #1b5e20;
}
.eval-6 {
  fill: #f3ef05;
}
</style>
